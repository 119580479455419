import { Route, BrowserRouter as Router, Link } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';

function PageOne() {
  return <div style={{ backgroundColor: '#282c34'}}>測試</div>
}

function Home() {
  return (
     <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <Link
          to="/pageone"
          style={{ color: '#e8e8e8' }}
        >
          direct to page one
        </Link>
      </header> 
  )
}

function MainRoute() {
  return ( <>
  <Route exact path='/' component={Home}/>
  <Route exact path="/pageone" component={PageOne}/>
  </>)
    {/* <Router> */}
     
    {/* </Router> */}
}

function App() {
  return (
    <div className="App">
      <Router>
     
      <MainRoute />
      </Router>
    </div>
  );
}

export default App;
